import { Box } from "@twilio-paste/core/box";
import { Text } from "@twilio-paste/core/text";

import { containerStyles, titleStyles } from "./styles/Header.styles";

export const Header = ({ customTitle }: { customTitle?: string }) => {
    return (
        <Box as="header" {...containerStyles} className="psicotest-header">
            <Text as="h2" {...titleStyles}>
                <img src="https://twilio.psicotestqa.com/Psicotest.png" alt="Logo" />
                {customTitle || "Centro de atención"}
            </Text>
        </Box>
    );
};
